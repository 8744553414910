<template>
    <div>
        <h4 class="bold text-uppercase">{{ $route.name }}</h4>
        <div class="row mt-4">
            <div class="col-md-6">
                <div class="row form-group">
                    <div class="col-md-3 col-12">
                        <h6 class="bold mb-0">Nhập danh sách SDT cần quét:</h6>
                    </div>
                    <div class="col-md col-12">
                        <textarea
                            v-model="uid_input"
                            rows="5"
                            class="form-control input-light"
                            required
                            placeholder="Nhập SDT cần quét, mỗi SDT cách nhau 1 dòng"
                        ></textarea>
                        <div class="row form-group mt-4">
                            <div class="col-12 text-center bold">
                                <div class="card card-info">
                                    <div class="card-body">
                                        <h5 class="font-bold">
                                            Tổng:
                                            <span class="bold green"
                                                ><number
                                                    ref="number1"
                                                    :from="0"
                                                    :to="price * quantity"
                                                    :format="theFormat"
                                                    :duration="1"
                                                    :delay="0"
                                                    easing="Power1.easeOut"
                                                />
                                                {{ currency }}</span
                                            >
                                        </h5>
                                        <h6 class="mb-0">
                                            Bạn sẽ quét FB ID của <span class="bold green">{{ quantity }} SĐT</span> với
                                            giá <span class="green bold">{{ price | formatNumber }}</span>
                                            {{ currency }} / SĐT thành công
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button
                            @click="convert()"
                            :class="['btn btn-block btn-dark-blue btn-rounded bold mt-4']"
                            type="submit"
                        >
                            Tiến hành
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <datatable title="Kết quả" stt="true" :columns="columnsTableLogs" :rows="list_success"> </datatable>
            </div>
        </div>
    </div>
</template>
<script>
import datatable from "../../../../components/datatable/datatable"
import { convertPhoneUID } from "../../../../api/services/other"
export default {
    name: "spam-sms-order",
    components: { datatable },
    data() {
        return {
            uid_input: "",
            specialTool: {
                toolName: "Convert Uid To Phone",
                package_name: "convert_uid_to_phone"
            },
            columnsTableLogs: [
                {
                    label: "SDT",
                    field: "phone"
                },
                {
                    label: "Avatar",
                    field: "fb_id",
                    avatar: true,
                    avatar_size: "40"
                },
                {
                    label: "Fb ID",
                    field: "fb_id",
                    fb_link: true
                }
            ],
            result_uid: [],
            modal_type: "error"
        }
    },
    computed: {
        currency() {
            return this.$store.state.site.site.currency
        },
        prices() {
            let tool = this.$store.state.tools.tools.find(tool => tool.id === 56)
            return tool.prices
        },
        list_uid() {
            return this.uid_input ? this.uid_input.trim().match(/.+/g) : []
        },
        list_success() {
            return this.result_uid.filter(item => item.fb_id)
        },
        quantity() {
            return this.scan_type === "link" ? 1 : this.list_uid.length
        }
    },
    async created() {
        this.getPriceSeedingType()
    },
    methods: {
        convert: async function() {
            this.result_uid = []
            let uidArr = this.list_uid.slice()
            let promises = uidArr.map(uid => {
                this.result_uid.push({ fb_id: "", phone: uid })
                return convertPhoneUID({ object_id: uid.replace(/ /g, "").match(/.+/g), type: "phone" })
            })
            let countSuccess = 0
            let dataResponse = await Promise.all(promises)
            let idx = 0
            for (let index = 0; index < dataResponse.length; index++) {
                const element = dataResponse[index]
                if (element.status === 200) {
                    if (typeof element.data !== "string") {
                        for (let i = 0; i < element.data.length; i++) {
                            try {
                                if (!element.data[i][this.result_uid[idx].phone].error) {
                                    const length = element.data.length
                                    this.result_uid[idx].fb_id = element.data[i][this.result_uid[idx].phone]
                                    if (length - i > 1) {
                                        idx++
                                        this.result_uid.splice(idx, 0, { phone: this.result_uid[idx - 1].phone })
                                    }
                                    countSuccess++
                                }
                            } catch (error) {
                                const length = element.data.length
                                this.result_uid[idx].fb_id = element.data[i][this.result_uid[idx].phone]
                                if (length - i > 1) {
                                    idx++
                                    this.result_uid.splice(idx, 0, { phone: this.result_uid[idx - 1].phone })
                                }
                                countSuccess++
                            }
                        }
                    } else this.result_uid[idx].fb_id = element.data
                }
                idx++
            }
            countSuccess > 0
                ? this.$swal("Hoàn thành", `Đã convert thành công ${countSuccess} uid`, "success")
                : this.$swal("Thất bại", "Convert không thành công, vui lòng thử List Uid khác", "error")
            // this.$store.dispatch("USER_GET_INFO")
        },
        theFormat(number) {
            return number
                ? Math.round(Number(number))
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : 0
        },
        getPriceSeedingType: function() {
            let price = this.prices.find(price => price.package_name.includes("convert_uid_to_phone"))
            if (price) {
                this.price = price.price
            }
        }
    }
}
</script>
